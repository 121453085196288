const CHE = {
  /**
   * 添加 label
   * @param cartesian
   * @param graphicOption
   * @param entityOption
   * @returns {Entity}
   */
  addLabel(cartesian, graphicOption = {}, entityOption = {}) {
    let label = viewer.entities.add({
      position: cartesian,
      label: {
        text: "未命名",
        // 关于font格式：https://developer.mozilla.org/en-US/docs/Web/CSS/font
        font: "bold 12pt sans-seri",
        fillColor: Cesium.Color.fromCssColorString("#e7d111"),
        // showBackground: true,
        // backgroundColor: Cesium.Color.fromCssColorString("#000000E5"),
        style: Cesium.LabelStyle.FILL_AND_OUTLINE,
        outlineWidth: 2,
        outlineColor: Cesium.Color.fromCssColorString("#000"),
        // verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        pixelOffset: new Cesium.Cartesian2(0, -12),
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平位置
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直位置
        disableDepthTestDistance: Number.POSITIVE_INFINITY, // 去掉地形遮挡
        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 贴地
        scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e5, 0.2),
        ...graphicOption
      },
      ...entityOption
    });
    // console.log("label.id: ", label.id)
    return label;
  },

  addPolygon(cartesians, graphicOption = {}, entityOption = {}) {
    let polygon = viewer.entities.add({
      polygon: {
        hierarchy: new Cesium.PolygonHierarchy(cartesians),
        show: true,
        fill: true,
        material: Cesium.Color.RED.withAlpha(0.5),
        width: 3,
        outlineColor: Cesium.Color.BLACK,
        outlineWidth: 1,
        outline: false,
        clampToGround: true,
        ...graphicOption
      },
      ...entityOption
    });

    return polygon

  },

  /**
   * 通过 关键词 移除 实体
   * @param keyword
   * @param entityKey
   */
  removeEntityByKeyword(keyword, entityKey = "id") {
    let entities = this.getEntitiesByKeyword(keyword, entityKey)
    entities.forEach(e => viewer.entities.remove(e))
  },

  /**
   * 通过 关键词 获取指定实体
   * @param keyword
   * @param entityKey
   * @returns {*[]}
   */
/*  getEntitiesByKeyword(keyword, entityKey = "name") {
    let entities = []
    viewer.entities.values.forEach(entity => {
      if (entity[entityKey] && entity[entityKey].indexOf(keyword) !== -1) {
        entities.push(entity)
      }
    })
    return entities
  }*/
    getEntitiesByKeyword(keyword, entityKey = "name") {
    let entities = []
    viewer.entities.values.forEach(entity => {
      if (entity[entityKey] && entity[entityKey].indexOf(keyword) !== -1) {
        entities.push(entity)
      }
    })
    return entities
  }

}

export default CHE
