<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  components: {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  }
}
</script>
<style lang="stylus">
@import './styles/base.styl'
@import './styles/toolTip.styl'
@import './styles/custom-el.styl'
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  color #2c3e50
  width 100%
  height 100%
  //min-width: 1280px;
  //min-height: 720px;
</style>
