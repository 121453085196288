<template lang="pug">
.main#main
    .sys-title()
      transition(name="el-fade-in-linear")
        .name(v-show="showCompName")
          .ch 厦门市鹭江街道三维警务服务系统
    InfoCard
    MapContainer
    QueryTool
</template>

<script>
// @ is an alias to /src
import InfoCard from "views/pages/InfoCard";
import MapContainer from "views/pages/MapContainer";
import QueryTool from "views/pages/QueryTool";

export default {
  name: "Home",
  components: {
    InfoCard,
    MapContainer,
    QueryTool
  },
  data() {
    return {
      showCompName: true
      // menuCode: "load", // 菜单code
      // menuName: "加载数据", // 菜单名字
      // showCompName: false
    }
  },
  created() {
    // this.$router.go(0)
  },
  mounted() {
    //
  },
  methods: {
    // 改变菜单
    changeView(code) {
      this.showCompName = !code || ["layer", "compare"].indexOf(code) !== -1;
    }
  },
  beforeDestroy() {
    this.$refs.nav.menuCode = ""
  }
};
</script>
<style lang="stylus" scoped>
.main
  width 100%
  height 100%

  .sys-title
    position absolute
    z-index 666
    margin 0
    height 80px
    text-shadow: 2px 2px 1px #000;
    display flex

    &.narrow
      .ch
        font-size: 28px;

      .en
        font-size 14px

    .logo
      box-sizing border-box
      width 80px
      height 80px
      background-color #2a2b2e
      border-bottom 1px solid #3dac61
      padding 21px
      img
        width 38px

    .name
      padding-left 24px
      height 80px
      display flex
      flex-direction column
      justify-content center

      .ch
        letter-spacing: 4px;
        color white
        font-family: fzzy;
        font-size: 28px;

      .en
        letter-spacing: 1.2px;
        color white
        font-size 16px
        font-family: cursive;

  .test
    position absolute
    top 100px
    left 200px
    z-index 999
</style>
