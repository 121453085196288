<template lang='pug'>
transition(name="el-zoom-in-top")
  .info-card-content(v-show="show")
    .card-header.flex-row
      .close-button.flex-row.flex-center(@click="show = false")
        img(:src="require(`@/assets/image/infoCard/close.png`)")
    .card-content
      .content-title
        .title-label(style="color: #41c4ed") {{ properties['mingcheng'] }}
      .content-list.flex-column
        .list-item.flex-row(
          style="margintop: 0",
          v-for="(value, key) in properties"
        )
          .item-label(
            v-if="['mingcheng','biaozhundi'].indexOf(key) !== -1"
          ) {{ (keyAndDesc.find(item=>item.id==key)).name+ '：' }}
          .item-value(
            v-if="['mingcheng','biaozhundi'].indexOf(key) !== -1 && !editFlag"
          ) {{ value }}


</template>

<script>
import x2js from "x2js";
import { restore, data2XML } from "@/utils/utils";
import { getFeatureInfo, updateFeatureInfo } from "@/api/query";
import cMapUtil from "@/utils/map_ce/cesiumBaseUtil";
export default {
  name: "InfoCard",
  data() {
    return {
      show: false,
      imgSrc: "",
      oldProperties: {},
      properties: {
        name: "名称",
        img: "0.png",
        describe: "描述文字",
      },
      editFlag: false,
      keyAndDesc: [
        { id: "objectid", name: 'objectid' },
        { id: "mingcheng", name: '名称' },
        { id: "biaozhundi", name: '标准地址' },
        { id: "danweishux", name: '单位属性' },
        { id: "fanwushuxi", name: '房屋属性' },
        { id: "jianzhushu", name: '建筑属性' },
        { id: "xuhao", name: '序号' },
        { id: "jianzhumin", name: '建筑名称' }
      ]
    };
  },
  watch: {
    // show(newV) {
    //   document.getElementsByClassName("cesium-performanceDisplay-defaultContainer")[0].style.display = newV ? "none" : ""
    // }
  },
  mounted() {
    // this.$bus.$once("viewerIsInit", e => {
    //   this.show = true
    // })
    this.$bus.$on("openInfoCard", (e) => {
      if (!e) return (this.show = false);
      this.properties = { ...e.properties };
      this.show = true;
    });
  },
  methods: {
    // 编辑模式
    openEdit() {
      this.oldProperties = { ...this.properties };
      this.editFlag = !this.editFlag;
    },
    //
    saveEdit(flag) {
      if (!flag) {
        this.properties = { ...this.oldProperties };
      } else {
        let tempData = data2XML(this.properties);
        //console.log(restore(this.properties));
        updateFeatureInfo(tempData, this.properties["objectid"]).then((res) => {
          //console.log(res.data);
        });

        console.log("objectid:" + this.properties["objectid"]);
        //console.log("id:"+this.properties["id"]);
        let label = cMapUtil.HandleEntity.getEntitiesByKeyword(
          "fid" + this.properties["objectid"],
          "name"
        );
        console.log(label.length);
        let position = label[0].position;
        //console.log(...position._value);

        //cMapUtil.HandleEntity.removeEntityByKeyword("产权人图幅号","name");
        cMapUtil.HandleEntity.removeEntityByKeyword(
          "fid" + this.properties["objectid"],
          "name"
        );

        cMapUtil.HandleEntity.addLabel(
          position._value,
          {
            text: this.properties["产权人"] + "\n" + this.properties["图幅号"],
            pixelOffset: new Cesium.Cartesian2(0, -3),
            scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.8e3, 0.2),
            heightReference: Cesium.HeightReference.NONE,
          },
          {
            name: "fid" + this.properties["objectid"],
          }
        );

        //console.log(tempData)
        //let tempData=restore(this.properties);
        //console.log(tempData)
        //var x2jsInstance=new x2js();
        //console.log(x2jsInstance.js2xml({...tempData}))
      }
      this.editFlag = false;
    },
  },
  beforeDestroy() {
    this.$bus.$off("openInfoCard");
  },
};
</script>

<style lang="stylus" scoped>
.info-card-content {
  position: absolute;
  right: 32px;
  top: 80px;
  width: 300px;
  height: fit-content;
  min-height: 300px;
  z-index: 999;
  background-color: #303235;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 2px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.card-header {
  position: absolute;
  width: 100%;
  height: 50px;
  user-select: none;
  border-radius: 8px;

  // background-image: linear-gradient(rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.3) 30%, rgba(0, 0, 0, 0) 100%);
  .close-button {
    position: absolute;
    right: 6px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background: #28292c52;
    }

    img {
      width: 16px;
    }
  }
}

.card-img {
  width: 300px;
  height: 180px;
  border-radius: 8px 8px 0 0;
}

.card-content {
  color: #e8eaed;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;

  .content-title {
    font-size: 22px;
  }

  .describe-text {
    margin-top: 12px;
    line-height: 20px;
  }

  .content-list {
    margin-top: 14px;

    .list-item {
      margin: 6px 0 4px 0;
      align-items: start;
      min-height: 30px;

      .el-link {
        width: fit-content !important;
        margin-top: 8px;
      }

      .item-label {
        text-align: right;
        color: #939599;
        width: 90px;
      }

      .item-value {
        width: 160px;
      }
    }
  }
}

.edit-button {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.item-value-input {
  >>>.el-input__inner {
    background-color: #2e2e2e !important;
    color: antiquewhite !important;
  }
}
</style>
