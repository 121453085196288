<template lang='pug'>
.map-response-template
</template>

<script>
// 依赖
import * as turf from "@turf/turf";
// 工具
import cMapUtil from "@/utils/map_ce/cesiumBaseUtil";
import { getFeatureInfo, updateFeatureInfo } from "@/api/query";

export default {
  name: "MapResponse",
  data() {
    return {
      vectorPolygon: null,
      flashingPolygonId: "dahuubj56da151ada5d1asda151da", // 单体查询（闪烁面）
      popupObj: null, // 弹窗对象
    };
  },
  watch: {
    //
  },
  mounted() {
    // 单击了倾斜摄影模型
    this.$bus.$on("mapClick:3DTileset", (position) => {
      let lnglat = cMapUtil.CoordTransform.cartesianToLngLatHeight(position);
      this.structureQuery(lnglat[0], lnglat[1]);
    });

    this.$bus.$on("closeCesiumPopup", (e) => {
      cMapUtil.HandleEntity.removeEntityByKeyword(this.flashingPolygonId);
    });
  },
  methods: {
    // 单体化查询
    async structureQuery(lng, lat) {
      let objectFeature = "";

      await getFeatureInfo(lng, lat).then((res) => {
        //console.log(res.data.timeStamp)
        objectFeature = res.data;
      });
      if (objectFeature.totalFeatures > 0) {
        let target = objectFeature.features[0];
        console.log(target);
        this.renderPolygon_hjs(target);
        let properties = target.properties;

        this.$bus.$emit("openInfoCard", { properties });
        // 飞行至目标（采集地形）
        let poly = turf.polygon(target.geometry.coordinates[0]);
        let center = turf.centerOfMass(poly);
        let centerLng = center.geometry.coordinates[0];
        let centerLat = center.geometry.coordinates[1];

        let Cartesian3 = cMapUtil.CoordTransform.lngLatHeightToCartesian(
          centerLng,
          centerLat,
          500
        );

        cMapUtil.ViewerControl.flyToPoint(Cartesian3, 344, -42, 700)
      }

      //updateFeatureInfo()
      //cMapUtil.HandleEntity.removeEntityByKeyword("产权人图幅号","name");
      /*
      if (!this.vectorPolygon) {
        const temp = await import("@/assets/data/geoData/test.json")
        this.vectorPolygon = temp.default
      }
      const buildingBounds = this.vectorPolygon.features
      let targetPolygon = this.findTargetFromPolygons(lng, lat, buildingBounds)
      if (targetPolygon) {
        this.renderPolygon(targetPolygon)
        let properties = targetPolygon.properties
        this.$bus.$emit("openInfoCard", { properties });

        // 飞行至目标（采集地形）
        let Cartesian3 = cMapUtil.CoordTransform.lngLatHeightToCartesian(properties.centerLng, properties.centerLat, 10)
        cMapUtil.ViewerControl.flyToPoint(Cartesian3, 0, -45, 120)
      }
      */
    },

    /**
     * 查找目标面
     * @param lng
     * @param lat
     * @param features
     * @returns {boolean}
     */
    findTargetFromPolygons(lng, lat, features) {
      let result;
      let pt = turf.point([lng, lat]);
      features.forEach((buildingFeature) => {
        let temp = { ...buildingFeature };
        let coordinates = temp.geometry.coordinates[0][0];
        let poly = turf.polygon([coordinates]);
        let inFlag = turf.booleanPointInPolygon(pt, poly);
        if (inFlag) {
          let center = turf.centerOfMass(poly);
          if (!temp.properties.centerLng)
            temp.properties.centerLng = center.geometry.coordinates[0];
          if (!temp.properties.centerLat)
            temp.properties.centerLat = center.geometry.coordinates[1];
          result = temp;
        }
      });
      return result;
    },

    // 添加闪烁面
    renderPolygon(polygon) {
      let x = 0.6;
      let flog = true;
      cMapUtil.HandleEntity.removeEntityByKeyword(this.flashingPolygonId);

      let cartesians = polygon.geometry.coordinates[0][0].map(
        (lngLatHeight) => {
          return cMapUtil.CoordTransform.lngLatHeightToCartesian(
            lngLatHeight[0],
            lngLatHeight[1],
            lngLatHeight[2]
          );
        }
      );
      cMapUtil.HandleEntity.addPolygon(
        cartesians,
        {
          material: new Cesium.ColorMaterialProperty(
            new Cesium.CallbackProperty(function () {
              let step = 0.02;
              if (flog) {
                x = x - step;
                if (x <= 0) flog = false;
              } else {
                x = x + step;
                if (x >= 0.6) flog = true;
              }
              return Cesium.Color.fromCssColorString("#33E4C9").withAlpha(x); // #33E4C9
            }, false)
          ),
        },
        {
          id: this.flashingPolygonId,
        }
      );
    },

    // 添加闪烁面
    renderPolygon_hjs(polygon) {

      let x = 0.6;
      let flog = true;
      cMapUtil.HandleEntity.removeEntityByKeyword(this.flashingPolygonId);

      let cartesians = polygon.geometry.coordinates[0][0].map(
        (lngLatHeight) => {
          return cMapUtil.CoordTransform.lngLatHeightToCartesian(
            parseFloat(lngLatHeight[0]),
            parseFloat(lngLatHeight[1]),
            parseFloat(lngLatHeight[2])
          );
        }
      );

      cMapUtil.HandleEntity.addPolygon(
        cartesians,
        {
          material: new Cesium.ColorMaterialProperty(
            new Cesium.CallbackProperty(function () {
              let step = 0.02;
              if (flog) {
                x = x - step;
                if (x <= 0) flog = false;
              } else {
                x = x + step;
                if (x >= 0.6) flog = true;
              }
              return Cesium.Color.fromCssColorString("#33E4C9").withAlpha(x); // #33E4C9
            }, false)
          ),
        },
        {
          id: this.flashingPolygonId,
        }
      );
    },
  },

  beforeDestroy() {
    cMapUtil.HandleEntity.removeEntityByKeyword(this.flashingPolygonId);
    this.$bus.$off("closeCesiumPopup");
    this.$bus.$off("mapClick:3DTileset");
  },
};
</script>

<style lang="stylus" scoped></style>
