import Vue from "vue";
import App from "./App.vue";
import router from "./router";
//import store from './utils/store'
// import axios from "axios";

// Vue.prototype.$http = axios;
Vue.prototype.$bus = new Vue(); // 用于传递事件
Vue.config.productionTip = false;

window._GlobalStatus = {
  measureFlag: false, // 是否正在测量
  viewerIsInit: false, // viewer初始化完毕
  firstTilesLoaded: false, // 默认初始3D tile加载完毕
  firstBasemapLoaded: false, // 默认初始底图加载完毕
  enableControlFlag: false, // 是否仅用了用户操作地图
  homeCamera: {
    "lng": 118.094,
    "lat": 24.441,
    "height": 4000,
    "heading": 328,
    "pitch": -49,
    "roll": 0
  }
}

// 引入字体
import "@/styles/font/font.css"

// 引入element
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

// 引入echarts
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

// 引入cesium
import "cesium/Build/Cesium/Widgets/widgets.css";
import * as Cesium from "cesium";
window.Cesium = Cesium;
// 全局路由构造函数，判断是否登录和要跳转到页面
router.beforeEach((to, from, next) => {
  if(to.meta.title){
    document.title=to.meta.title
  }
  console.log("router.beforeEach:");
  console.log("token:" + window.localStorage.token);
  console.log("isLogin:" + window.localStorage.isLogin)
  if (window.localStorage.token && window.localStorage.isLogin ) {
    console.log("token:" + window.localStorage.token);
    next()
  } else if (to.path !== '/login') {
    let token = window.localStorage.token;
    if (token === 'null' || token === '' || token === undefined) {
      next({ path: '/login' })
      Toast({ message: '检测到您还未登录,请登录后操作！', duration: 1500 })
    }
  } else {
    next()
  }
  /*} else {   // 不需要登录
    next()
  }*/
})
new Vue({
  router,
  render: (h) => h(App)
}).$mount("#app");
