/**
 * 1. 初始化地图
 * 2. 初始化侦听器：单击打开弹窗；鼠标移动改变样式；右键单击移除marker
 */
import Vue from "vue";
import cMapUtil from "@/utils/map_ce/cesiumBaseUtil/index";

// 初始化地图
export const initMap = function(vm) {
  window.viewer = null;
  Cesium.Ion.defaultAccessToken = window.CF_CesiumToken
  // 天地图初始底图

  // 添加mapbox初始地图
  let mapboxToken = window.CF_MapBoxToken
  let mapboxLayer = new Cesium.MapboxStyleImageryProvider({
    styleId: "satellite-v9",
    accessToken: mapboxToken
  });
   //viewer.imageryLayers.addImageryProvider(mapboxLayer); 

  // 默认视角
  Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(
      75.0, // 东
      0.0, // 南
      140.0, // 西
      60.0 // 北
  );

  console.log("初始化viewer")
  window._GlobalStatus.firstBasemapLoaded = false
  window.viewer = new Cesium.Viewer("mapContainer", {
    sceneMode: Cesium.SceneMode.SCENE3D,
    infoBox: false, // 信息框
    selectionIndicator: false, // 绿色框

    geocoder: false, // 是否显示geocoder小器件，右上角查询按钮
    homeButton: false, // 是否显示Home按钮
    sceneModePicker: false, // 是否显示3D/2D选择器
    baseLayerPicker: false, // 是否显示图层选择器

    navigationHelpButton: false, // 是否显示右上角的帮助按钮
    animation: false, // 是否创建动画小器件，左下角仪表
    timeline: false, // 是否显示时间轴
    fullscreenButton: false, // 是否显示全屏按钮
    orderIndependentTranslucency: false,
    contextOptions: {
      webgl: { alpha: true }
    },
    //imageryProvider: mapboxLayer // 天地图/mapbox //M
  });

  viewer.scene.globe.baseColor = Cesium.Color.fromCssColorString("#383017");
  // cesium 版权标识
  viewer.cesiumWidget.creditContainer.style.display = "none";
  // 设置homeButton的位置
  Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(118.055, 24.454, 118.084, 24.482);
  // 移除默认双击事件
  viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
  // 是否显示帧率信息
   viewer.scene.debugShowFramesPerSecond = false 

  viewer._cesiumWidget._supportsImageRenderingPixelated = Cesium.FeatureDetection.supportsImageRenderingPixelated();
  viewer._cesiumWidget._forceResize = true;

  // 指定光照方向
  viewer.scene.light = new Cesium.DirectionalLight({ // 去除时间原因影响模型颜色
    direction: new Cesium.Cartesian3(0.35492591601301104, -0.8909182691839401, -0.2833588392420772)
  });

  if (Cesium.FeatureDetection.supportsImageRenderingPixelated()) {
    var vtxf_dpr = window.devicePixelRatio;
    // 适度降低分辨率
    while (vtxf_dpr >= 2.0) {
      vtxf_dpr /= 2.0;
    }
    viewer.resolutionScale = vtxf_dpr;
  }

  // 可以监听切片的加载状态
  let helper = new Cesium.EventHelper();
  helper.add(viewer.scene.globe.tileLoadProgressEvent, (event) => {
    // console.log("每次加载矢量切片都会进入这个回调");
    if (event === 0 && !window._GlobalStatus.firstBasemapLoaded) {
      // console.log("这个是加载最后一个矢量切片的回调");
      window._GlobalStatus.firstBasemapLoaded = true
      hasInitFrame()

      // viewer.camera.flyTo({
      //   destination: Cesium.Cartesian3.fromDegrees(103.3, 14.716, 8534209.228),
      //   orientation: {
      //     heading: Cesium.Math.toRadians(0),
      //     pitch: Cesium.Math.toRadians(-78),
      //     roll: 0.0
      //   },
      //   duration: 1.0,
      //   complete: () => {
      //      hasInitFrame()
      //   }
      // });
    }
  });
};

// 框架初始化完毕
function hasInitFrame() {
  initListener();
  window._GlobalStatus.viewerIsInit = true
  Vue.prototype.$bus.$emit("viewerIsInit", { mapFrame: "cesium", msg: "地图已经准备好了" });
  // 增加点击事件，打印状态，仅用于开发调试
  const handlerEvent = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
  handlerEvent.setInputAction((event) => {
    if (window._GlobalStatus.measureFlag) return
    let ellipsoid = viewer.scene.globe.ellipsoid;
    let cartesian = viewer.camera.pickEllipsoid(event.position, ellipsoid);

    console.log("init: 点击处的屏幕坐标：", event.position)
    if (Cesium.defined(cartesian)) {
      const cartographic = Cesium.Cartographic.fromCartesian(cartesian) // 根据笛卡尔坐标获取到弧度
      const lon = Cesium.Math.toDegrees(cartographic.longitude) // 根据弧度获取到经度
      const lat = Cesium.Math.toDegrees(cartographic.latitude) // 根据弧度获取到纬度
      const height = cartographic.height // 球体高度
       console.log("init: 对应椭球表面：三维坐标：", cartesian, "经纬度：", lon.toFixed(3), lat.toFixed(3), height,
           "相机高度：", viewer.camera.positionCartographic.height.toFixed(3))
    }
  }, Cesium.ScreenSpaceEventType.LEFT_CLICK)
}

// 初始化监听
function initListener() {
  const initHandler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
  mouseMoveEnd(initHandler); // 鼠标样式
  leftClick(initHandler); // 鼠标点击
  // rightClick(initHandler); // 右键单击 删除标注
  // doubleClick(initHandler); // 鼠标双击
}

// 鼠标双击
function doubleClick(initHandler) {
  initHandler.setInputAction( (event) => {
    window._hasStopRotate = true
  }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK)
}

// 鼠标单击监听
function leftClick(initHandler) {
  initHandler.setInputAction((event) => {
    Vue.prototype.$bus.$emit("closeCesiumPopup", { msg: "closeCesiumPopup" });
    Vue.prototype.$bus.$emit("openInfoCard");

    // 默认禁用用户控制，需要通过双击开启
    // if (!window._hasStopRotate) {
    //   console.log("双击地图，开启控制")
    //   // Vue.prototype.$bus.$emit("openNotify", "双击地图，开启控制");
    //   return;
    // }

    // 正在测量中，屏蔽当前事件
    if (window._GlobalStatus.measureFlag) {
      return;
    }
    const pickedFeature = viewer.scene.pick(event.position);
    if (Cesium.defined(pickedFeature)) {
      // 单击了 3d tiles 模型
      if (pickedFeature.primitive instanceof Cesium.Cesium3DTileset) {
        let position = cMapUtil.NativeAPI.screenToCartesian3ByType(event.position.x, event.position.y, "model")
        Vue.prototype.$bus.$emit("mapClick:3DTileset", position);
      }
      // 单击了 entity
      if (pickedFeature.id && pickedFeature.id.name) {
        let entityName = pickedFeature.id.name;
        if (entityName.indexOf("delete#") !== -1) {
          Vue.prototype.$bus.$emit("deleteMeasure", pickedFeature.id.name);
        }
        if (entityName.indexOf("land#") !== -1) {
          // 获取属性信息
          let land = pickedFeature.id.properties.getValue("");
          Vue.prototype.$bus.$emit("landClick", {
            info: land,
            from: "entityClick"
          });
        }
        if (entityName === "building-icon") {
          let entityId = pickedFeature.id.id
          let properties = pickedFeature.id.properties.getValue("")
          Vue.prototype.$bus.$emit("openInfoCard", { entityId, properties });
        }
      }

      // if (pickedFeature.primitive instanceof Cesium.Billboard) {
      //     viewer._container.style.cursor = 'pointer'
      // }
    }
  }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
}

// 鼠标移动监听
function mouseMoveEnd(initHandler) {
  initHandler.setInputAction((movement) => {
    const pickedFeature = viewer.scene.pick(movement.endPosition);

    viewer._container.style.cursor = "default";
    if (Cesium.defined(pickedFeature)) {
      if (pickedFeature.id && pickedFeature.id.name) {
        // 根据 name 判断拾取目标
        let entityName = pickedFeature.id.name;
        if (entityName.indexOf("delete#") !== -1) {
          viewer._container.style.cursor = "pointer";
        }

        if (entityName === "building-icon") {
          viewer._container.style.cursor = "pointer";
          pickedFeature.id.label.show = true
          let tempEntity = pickedFeature.id
          setTimeout(() => {
            tempEntity.label.show = false
          }, 800)
        }
      }

      // if (pickedFeature.id && pickedFeature.id._billboard) {
      if (pickedFeature.primitive instanceof Cesium.Billboard) {
        viewer._container.style.cursor = "pointer";
      }
    }
  }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
}
