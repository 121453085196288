<template lang="pug">
#mapContainer()
    MapStatus(v-show="true" )
    MapResponse
    .slider-content
      .slider-bar#slider

</template>

<script>
import MapStatus from "@/components/mapWidget/MapStatus";
import MapResponse from "@/components/mapWidget/MapResponse";

import {initMap} from "@/utils/map_ce/map";
import cMapUtil from "@/utils/map_ce/cesiumBaseUtil";

export default {
  name: "MapContainer",
  components: {
    MapStatus,
    MapResponse
  },
  data() {
    return {
      //
    };
  },
  mounted() {
    this.$nextTick(() => {
      initMap();
    });
    // 地图加载完成
    this.$bus.$once("viewerIsInit", e => {
      this.loadTile();
      // this.loadGltf();

      // 测试的时候开启，地图刷新后执行
      // this.testButtonShou = true
    });
  },
  methods: {
        // 加载gltf
    loadGltf() {
      console.log(456)
      let modelUrl = "http://localhost:7080/webdata/glb/fangshan_gltf/fangshan.gltf"
      this.carEntity = viewer.entities.add({
        position: cMapUtil.CoordTransform.lngLatHeightToCartesian(115.977, 39.724, 450),
        model: {
          uri: modelUrl,
          minimumPixelSize: 64,
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
        }
      });
    },

    // 加载模型
    loadTile() {
      // 加载3DTiles
      // let url = 'http://61.54.226.243:21203/tiles/hnzg/tileset.json' // 河南某区域
      // let url = "https://gis-dev.fpi-inc.site/resource/model/osgb/cqyq/tileset.json"; // 重庆某区域
      // let url = "http://model.guihuao.com/23f5387b00fe904d0167a831738344bf/tileset.json"; // 农村
      let url = window.CF_MapConfig.tileUrl[process.env.NODE_ENV]; // 大雁塔
      this.tileset = new Cesium.Cesium3DTileset({
        url: url, // json文件地址
        show: true, // 是否显示
        maximumScreenSpaceError: 2,
        skipLevelOfDetail: true, // 开启跳级加载
        maximumMemoryUsage: 4024 // 最大内存占用
        // dynamicScreenSpaceError: true
      });
      this.tileset.colorBlendMode = Cesium.Cesium3DTileColorBlendMode.REPLACE;

      // 加载3Dtile
      viewer.scene.primitives.add(this.tileset);

      // 3dtile加载完成后执行
      this.tileset.readyPromise.then((tileset) => {
        // this.$bus.$emit("tilesetReady")
        // viewer.zoomTo(tileset); // 定位到3dtiles的位置

        this.flyToTileset()

        let boundingSphere = tileset.boundingSphere; // 边界球
        // 计算边界中心点位置
        let cartographic = Cesium.Cartographic.fromCartesian(boundingSphere.center);
        // 计算中心点位置的地表坐标
        let surface = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 0.0);
        // 高度偏差，向上是正数，向下是负数
        // let heightOffset = -11; // 有地形 自发布
        let heightOffset = -16; // 无地形
        // 偏移后的坐标
        let offset = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, heightOffset);
        let translation = Cesium.Cartesian3.subtract(offset, surface, new Cesium.Cartesian3());
        // tileset.modelMatrix转换
        tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
      });
    },

    // 定位到3dtiles的位置
    flyToTileset() {
      // 飞行到模型位置
      // let flyOffset = new Cesium.HeadingPitchRange(
      //     Cesium.Math.toRadians(0), // 水平方向的旋转角 0-360度
      //     Cesium.Math.toRadians(-30), // 垂直平面俯仰角 0-90度
      //     460
      // );
      // viewer.flyTo(this.tileset, {
      //   duration: 2.0,
      //   offset: flyOffset,
      //   maximumHeight: 10000
      // }
      viewer.scene.camera.moveEnd.addEventListener(this.moveEndListener);

      // 飞行到指定地点
      if (window._GlobalStatus.homeCamera) {
        let {lng, lat, height, heading, pitch, roll} = window._GlobalStatus.homeCamera
        cMapUtil.ViewerControl.cameraFlyToPosition(lng, lat, height, heading, pitch, roll)
      }
    },

    moveEndListener() {
      // console.log("移动结束")
      this.$bus.$emit("tilesetReady")
    }
  },
  beforeDestroy() {
    if (this.setIntervalID) clearInterval(this.setIntervalID)
    window._GlobalStatus.firstTilesLoaded = false;
    //

    this.$bus.$off("viewerIsInit");

    if (viewer) viewer.destroy();
    window.viewer = null;
  }
};
</script>

<style lang="stylus" scoped>
#mapContainer
  z-index 111
  position relative
  margin-left 0px
  width calc(100% - 0px)
  height calc(100% - 0px)
  background-repeat: round;

.slider-content
  position absolute
  height 100%
  left 0
  width 100%
  min-width 1200px
  pointer-events none
  z-index 222

  .slider-bar
    position relative
    box-sizing border-box
    display none
    cursor w-resize
    transform translate(-50%, 0)
    pointer-events auto
    height 100%
    width 4px
    //border-left 4px solid #8c2e3c
    //border-right 4px solid #083788
    background-color #41aa42
    left 50%

</style>
