import Vue from "vue";
import VueRouter from "vue-router";
import Home from "views/Home.vue";
import Login from "views/pages/Login.vue";

Vue.use(VueRouter);

const routes = [
  { path: '/', redirect: '/home' },

  {
    path: "/home",
    name: "Home",
    component: Home,
    requiresAuth: true,
    title: "厦门市鹭江街道三维警务服务系统",
    meta: {
      title: "厦门市鹭江街道三维警务服务系统"
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    title: "登录",
    meta: {
      title: "登录"
    }
  },
];

const router = new VueRouter({
  mode: 'hash',//history
  routes
});

export default router;
