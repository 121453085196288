<template lang='pug'>
  div.map-status
    .sys-name 技术支持：中测星图
    .foot
      .status-content()
        .status-item.mouse-position
          img(src="@/assets/mapstatus/mouse.svg")
          span#mouse-position
        .status-item.camera-position
          img(src="@/assets/mapstatus/camera.svg")
          span#camera-position
        .status-item.camera-height
          img(src="@/assets/mapstatus/height.svg")
          span#camera-height
        .status-item.camera-attitude
          img(src="@/assets/mapstatus/attitude1.svg")
          span#camera-attitude
</template>

<script>
import cMapUtil from "@/utils/map_ce/cesiumBaseUtil";

export default {
  name: "MapStatus",
  data() {
    return {
      limitFlag: false,
      mapMode: "3D",
      cameraPosition: {
        lng: "",
        lat: "",
        height: ""
      },
      // 鼠标位置
      mousePosition: {
        lng: "",
        lat: "",
        height: ""
      },
      // 姿态
      cameraOrientation: {
        heading: "",
        pitch: "",
        roll: ""
      },
      angle: -45
    }
  },
  mounted() {
    // 地图加载完成
    this.$bus.$once("viewerIsInit", e => {
      this.limitFlag = false
      this.initListener()
    })
  },
  methods: {
    initListener() {
      this.postRenderAdd(this.renderHandler); // renderHandler
      this.mouseHandler(); // renderHandler
    },
    postRenderAdd(theHandler) {
      viewer.scene.postRender.addEventListener(theHandler);
    },
    // cesium 监听每一帧
    renderHandler() {
      if (this.limitFlag) {
        return
      }
      let camera = viewer.camera;

      // 三维视图下，这两个一样，可以正常转经纬度。二维情况下cartographic_1的获取的不太对
      let cartographic_1 = Cesium.Cartographic.fromCartesian(camera.position);
      let cartographic = viewer.camera.positionCartographic;

      let camera_lng = Cesium.Math.toDegrees(cartographic.longitude).toFixed(3);
      let camera_lat = Cesium.Math.toDegrees(cartographic.latitude).toFixed(3);
      let camera_height = cartographic.height.toFixed(3);

      document.getElementById("camera-position").innerHTML = `${camera_lng},${camera_lat}`
      document.getElementById("camera-height").innerHTML = camera_height + "m"

      try { // 切换二三维的时候，会出错
        let heading = Cesium.Math.toDegrees(camera.heading).toFixed(0);
        this.angle = heading - 45
        let pitch = Cesium.Math.toDegrees(camera.pitch).toFixed(0);
        // this.cameraOrientation.roll = Cesium.Math.toDegrees(camera.roll).toFixed(0); // 其实一直是360/0
        let roll = 0

        document.getElementById("camera-attitude").innerHTML = `${heading},${pitch},${roll}`
      } catch (e) {
        //
      }

      if (!document.getElementById("mouse-position").innerHTML) {
        let mouse_lng = camera_lng
        let mouse_lat = camera_lat

        document.getElementById("mouse-position").innerHTML = `${mouse_lng},${mouse_lat}`
      }
    },
    // cesium 监听鼠标移动
    mouseHandler() {
      let scene = viewer.scene;
      let handler = new Cesium.ScreenSpaceEventHandler(scene.canvas);

      handler.setInputAction((movement) => {
        let ellipsoid = scene.globe.ellipsoid;
        let cartesian = scene.camera.pickEllipsoid(movement.endPosition, ellipsoid);

        if (cartesian) { // 能获取，显示坐标
          var cartographic = ellipsoid.cartesianToCartographic(cartesian);
          // var coords = '经度' + Cesium.Math.toDegrees(cartographic.longitude).toFixed(2) + ', ' + '纬度' + Cesium.Math.toDegrees(
          //     cartographic.latitude).toFixed(2) + '高度 ' + Math.ceil(viewer.camera.positionCartographic.height);
          let mouse_lng = Cesium.Math.toDegrees(cartographic.longitude).toFixed(3)
          let mouse_lat = Cesium.Math.toDegrees(cartographic.latitude).toFixed(3)

          document.getElementById("mouse-position").innerHTML = `${mouse_lng},${mouse_lat}`

        } else { // 不能获取不显示
          // this.mousePosition.lng = 'null'
          // this.mousePosition.lat = 'null'
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    }
  }
}
</script>

<style lang="stylus" scoped>
.map-status
  //position relative
  bottom: 0;
  right: 0;
.sys-name
  position: absolute;
  left 20px
  bottom: 0px;
  z-index: 999;
  height: 32px;
  line-height: 32px;
  color: #fff;
  font-size: 14px;
.control-content
  width 160px
  height 160px
  position absolute
  right 0
  bottom 32px
  //background-color rgba(255, 255, 0, 0.44)
  z-index 222
  pointer-events: none;
  transition: all .5s;

  .control-item
    pointer-events auto
    background-color rgba(42, 43, 46, 0.8)
    width 36px
    height 36px
    border-radius 50%
    display flex
    justify-content center
    align-items center
    cursor pointer
    position relative

    &:hover
      background-color #494b50

.custom-compass
  position absolute
  bottom: 60px;
  right: 34px;
  z-index 999
  cursor pointer

.foot
  position absolute
  bottom 0
  z-index 222
  background: rgba(0, 0, 0, .6)
  width 100%
  height 32px

.status-content
  z-index: 999;
  width fit-content
  height 24px
  position absolute
  bottom 4px
  right 4px
  border-radius 4px
  display flex
  align-items center

.status-item
  margin-right 8px
  color #fff
  font-size 14px
  display flex
  align-items center

  img
    margin 0 4px
    width 16px
    height 16px

.camera-attitude img
  margin 0 2px
  width 18px
  height 18px
</style>
