<template lang='pug'>
.quert-tool
  input.custom-input(
    type="search",
    placeholder="搜索：名称",
    v-model="searchName",
    @focus="searchFoucs = true",
    @blur="searchFoucs = false",
    :class="{ 'custom-input-focus': searchName || searchFoucs }"
  )
  .search-result-content(v-if="searchName")
    .search-label
      span(
        style="fontsize: 18px; fontweight: 700",
        v-if="searchRresult.length === 0"
      ) 无结果
      span(
        style="fontsize: 18px; fontweight: 700",
        v-if="searchRresult.length > 0"
      ) 搜索结果
      span(
        style="color: #3296fa; fontsize: 14px",
        v-if="searchRresult.length > 0"
      ) {{ '（' + searchRresult.length + '个）' }}
    .result-content
      .result-item(v-for="i in searchRresult", @click="searchResultClick(i)") {{  i.properties["mingcheng"] }}
</template>

<script>
import polygonData from "@/assets/data/geoData/test.json";
import * as turf from "@turf/turf";
import cMapUtil from "@/utils/map_ce/cesiumBaseUtil";
import { getGeoJson } from "@/api/query";

export default {
  name: "QueryTool",
  data() {
    return {
      searchFoucs: false,
      searchName: null, // 搜索关键词
      searchRresult: [], // 搜索结果
    };
  },

  computed: {
    //
  },
  watch: {
    // 搜索关键词变化
    searchName(newV, oldV) {
      if (newV !== oldV && newV) {
        this.inputOnchange(newV);
      } else {
        this.searchRresult = [];
      }
    },
  },
  mounted() {
    this.$bus.$once("tilesetReady", (e) => {

       console.log("开始向地图上添加数据")
      this.addLabelOnMap();
    });
    this.$nextTick(() => {
      // console.log(polygonData)
    });
  },
  methods: {
    // 添加lable
    async addLabelOnMap() {
      await getGeoJson().then((res) => {
        res.data.features.forEach((feat) => {
          //console.log(feat.properties["图幅号"]);
          let coordinates = feat.geometry.coordinates[0][0];
          let poly = turf.polygon([coordinates]);
          let center = turf.centerOfMass(poly);

          let centerLng = center.geometry.coordinates[0];
          let centerLat = center.geometry.coordinates[1];
          feat.properties.centerLng = centerLng;
          feat.properties.centerLat = centerLat;

          let position = cMapUtil.CoordTransform.lngLatHeightToCartesian(
            centerLng,
            centerLat,
            60
          );

          if (viewer.scene.sampleHeightSupported) {
            let newCartesian = viewer.scene.clampToHeight(position);
            if (newCartesian) position = newCartesian;
          }
          console.log(feat.properties["mingcheng"])
          cMapUtil.HandleEntity.addLabel(
            position,
            {
              text:
                feat.properties["mingcheng"] ,
              pixelOffset: new Cesium.Cartesian2(0, -3),
              scaleByDistance: new Cesium.NearFarScalar(8e2, 1.0, 1.5e3, 0.6),
              heightReference: Cesium.HeightReference.NONE,
            },
            {
              name: "fid" + feat.properties["objectid"],
            }
          );
          // console.log(center)
        });
      });
    },
    // 搜索值变化
    async inputOnchange(newV) {
      // 从表格中查询结果
      // eslint-disable-next-line array-callback-return

      let geoData = [];
      await getGeoJson().then((res) => {
        geoData = res.data.features;
      });

      let result = geoData.filter((land) => {
        if (
          (land.properties["mingcheng"] &&
            land.properties["mingcheng"].indexOf(newV) !== -1) 
        ) {
          return land;
        }
      });
      this.searchRresult = result;
    },
    // 点击搜索结果
    searchResultClick(r) {
      console.log("点击搜索结果: ", r);
      // 获取面的中心点
      // let coordinates = r.geometry.coordinates[0][0]
      // let poly = turf.polygon([coordinates]);
      // let center = turf.centerOfMass(poly);
      // if (!r.properties.centerLng) r.properties.centerLng = center.geometry.coordinates[0]
      // if (!r.properties.centerLat) r.properties.centerLat = center.geometry.coordinates[1]

      let coordinates = r.geometry.coordinates[0][0];
      let poly = turf.polygon([coordinates]);
      let center = turf.centerOfMass(poly);
      let position = cMapUtil.CoordTransform.lngLatHeightToCartesian(
        center.geometry.coordinates[0],
        center.geometry.coordinates[1]
      );

      this.$bus.$emit("mapClick:3DTileset", position);

    },
  },
  beforeDestroy() {
    this.$bus.$off("openInfoCard");
  },
};
</script>

<style lang="stylus" scoped>
.quert-tool {
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 80px;
  left: 20px;
  font-size: 14px;
  z-index: 116;

  .search-result-content {
    box-sizing: border-box;
    box-shadow: #6666668c 0px 0px 4px;
    padding: 8px;
    margin-top: 10px;
    width: 212px;
    background-color: #2a2b2e;
    border-radius: 6px;
    transition: all 0.5s;

    .search-label {
      margin-bottom: 4px;
      color: #949494;
      font-weight: 700;
    }

    .result-content {
      color: #bdbdbd;
      max-height: 260px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #c6c6c6;
        border-radius: 8px;
      }

      .result-item {
        text-align: center;
        font-size: 14px;
        height: 28px;
        line-height: 28px;
        margin: 4px 0;
        cursor: pointer;
        color: #646464;
        border-radius: 4px;

        &:hover {
          color: #fff;
          background-color: #3d3f40;
        }
      }
    }
  }

  .custom-input {
    outline: none;
  }

  .custom-input {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    font-family: inherit;
  }

  .custom-input {
    background: #2a2b2e url('../../assets/image/search.png') no-repeat 9px center;
    border: solid 1px #35894c;
    padding: 9px 10px 9px 32px;
    width: 40px;
    height: 20px;
    padding-left: 40px;
    color: white;
    -webkit-border-radius: 10em;
    -moz-border-radius: 10em;
    border-radius: 6px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
  }

  .custom-input-focus {
    width: 160px;
    background-color: #202124;
    border-color: #0ff;
    -webkit-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
    -moz-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
    box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  }
}
</style>
