export  function restore(vueObject) {
    var result = null;
    var type = Object.prototype.toString.call(vueObject);
    switch (type) {
        case '[object Array]':
            console.log('[object Array]');
            result = toArray(vueObject);
            break;
        case '[object Object]':
            console.log('[object Object]');
            result = toObject(vueObject);
            break;
        default:
            result = vueObject;
            break;
    }

    function toArray(vueArray) {
        var array = [];
        for (var index in vueArray) {
            var item = restore(vueArray[index]);
            array.push(item);
        }
        return array;
    }

    function toObject(vueObject) {
        var obj = new Object();
        for (var index in vueObject) {
            var item = restore(vueObject[index]);
            obj[index] = item;
        }
        return obj;
    }

    return result;
}

export function  data2XML(data){
    let result='';
    result+='<wfs:Property><wfs:Name>图幅号</wfs:Name><wfs:Value>'+data['图幅号']+'</wfs:Value></wfs:Property>';
    result+='<wfs:Property><wfs:Name>被征收人</wfs:Name><wfs:Value>'+data['被征收人']+'</wfs:Value></wfs:Property>';
    result+='<wfs:Property><wfs:Name>门牌号</wfs:Name><wfs:Value>'+data['门牌号']+'</wfs:Value></wfs:Property>';
    result+='<wfs:Property><wfs:Name>村民小组</wfs:Name><wfs:Value>'+data['村民小组']+'</wfs:Value></wfs:Property>';
    result+='<wfs:Property><wfs:Name>产权人</wfs:Name><wfs:Value>'+data['产权人']+'</wfs:Value></wfs:Property>';
    result+='<wfs:Property><wfs:Name>产权建筑面</wfs:Name><wfs:Value>'+data['产权建筑面']+'</wfs:Value></wfs:Property>';
    result+='<wfs:Property><wfs:Name>建筑面积</wfs:Name><wfs:Value>'+data['建筑面积']+'</wfs:Value></wfs:Property>';
    result+='<wfs:Property><wfs:Name>家庭成员</wfs:Name><wfs:Value>'+data['家庭成员']+'</wfs:Value></wfs:Property>';
    return result;
}

