const CVC = {
  /**
   *  定位到点
   * @param destination 目标点 Cartesian3
   * @param heading  偏航角 正北,由正北向东偏向为正
   * @param pitch  俯仰角 垂直向下， ENU局部坐标系中XY平面的旋转角度，平面下为负，上为正
   * @param range  距离
   * @param duration  飞行时间
   * @param maximumHeight  最大高度
   * @param callback
   */
  flyToPoint(destination, heading = 0.0, pitch = -90, range = 800.0, duration = 1.5, maximumHeight = 8000, callback = null) {

    console.log("flyToPoint line 14");
    try {
      if (!viewer) {
        console.log("三维球未初始化！");
        return;
      }
      if (!destination) {
        console.log("定位目标点不对！");
        return;
      }
      let boundingSphere = new Cesium.BoundingSphere(destination, 0.0);

      console.log("flyToPoint line 26");
      viewer.camera.flyToBoundingSphere(boundingSphere, {
        duration: duration,
        // maximumHeight: maximumHeight,
        complete: () => {
          if (callback) {
            callback();
          } else {
            console.log("定位失败！");
          }
        },
        cancel: () => {
          console.log("定位取消！");
        },
        offset: {
          heading: Cesium.Math.toRadians(heading),
          pitch: Cesium.Math.toRadians(pitch),
          range: range
        }
      });

      console.log("flyToPoint line 47");
    } catch (e) {
      console.log("定位失败");
    }

    console.log("flyToPoint line 52");
  },
  /**
   * 相机飞行到指定位置
   * @param lng 角度
   * @param lat
   * @param height
   * @param heading 角度
   * @param pitch
   * @param roll
   */
  cameraFlyToPosition(lng, lat, height, heading, pitch, roll = 0) {
    viewer.camera.flyTo({
      destination: Cesium.Cartesian3.fromDegrees(lng, lat, height),
      orientation: {
        heading: Cesium.Math.toRadians(heading),
        pitch: Cesium.Math.toRadians(pitch),
        roll: Cesium.Math.toRadians(roll)
      },
      duration: 1.5
    })
  }
}

export default CVC
