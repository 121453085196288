import http from '@/utils/http';

//const domain_name='gis.cqjyqf.com';
const domain_name='gis.hejisan.com';
const geoServerUrl = `https://${domain_name}/geoserver/wfs`;
//http://g.cqjyqf.com/geoserver/topp/ows
const workspaces_name='cite';
const layer_name='xmlj_4326_z1';

export function getFeatureInfo(x, y) {
    let typeName=`${workspaces_name}:${layer_name}`;
    var param = {
        service: 'WFS',
        version: '1.0.0',
        request: 'GetFeature',
        typeName: typeName,
        outputFormat: 'application/json',
        maxFeatures: 3200,
        srsName: 'EPSG:4326',
        CQL_FILTER: 'CONTAINS(geom,POINT(' + x + ' ' + y + '))'
    };
    return http.get(geoServerUrl, param)
}

export function updateFeatureInfo(data, fid) {
    let template = 
              `<wfs:Transaction service="WFS" version="1.0.0"
                xmlns:topp="http://www.openplans.org/topp"
                xmlns:ogc="http://www.opengis.net/ogc"
                xmlns:wfs="http://www.opengis.net/wfs">
                <wfs:Update typeName="${workspaces_name}:${layer_name}">`+
        data +
                    `<ogc:Filter>
                        <ogc:FeatureId fid="${layer_name}.${fid}"/>
                    </ogc:Filter>
                </wfs:Update>
              </wfs:Transaction>`;
    return http.postToGeoServer(geoServerUrl, template)
}

export function getGeoJson() {
    let url = `https://${domain_name}/geoserver/cite/ows?service=WFS&version=1.0.0&request=GetFeature`+
                `&typeName=cite%3A${layer_name}&maxFeatures=200&outputFormat=application%2Fjson`;
    return http.get(url, null);

}
