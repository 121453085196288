import cesiumCoordTransform from "@/utils/map_ce/cesiumBaseUtil/cesiumCoordTransform";
const CNA = {
  screenToCartesian3ByType(x, y, type = "ellipsoid"){
    if (!["ellipsoid", "terrain", "model"].includes(type)) {
      throw new Error(` (screenToCartesian3ByType:) type:"${type}", 然而应该取值'ellipsoid'或'terrain'或'model'`)
    }

    let pickCartesian2 = new Cesium.Cartesian2(x, y) // pickCartesian2 相当于 event.position
    let resultCartesian3;

    if (type === "model") {
      if (!viewer.scene.pickPositionSupported) {
        throw new Error(` (screenToCartesian3ByType:) type:"${type}", 不持支该类型`)
      }
      // 从场景的深度缓冲区中拾取相应的位置，返回笛卡尔坐标。
      resultCartesian3 = viewer.scene.pickPosition(pickCartesian2);
    }
    return resultCartesian3
  }
}

export default CNA
